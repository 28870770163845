import styled from "styled-components";
import CheckboxTick from "../../../assets/icons/checkbox.svg";

const StyledInput = styled.input`
    opacity: 0;
    visibility: hidden;
    position: absolute;
`;

const StyledSpan = styled.span`
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    cursor: pointer;
    width: 1.35rem;
    height: 1.35rem;
    border: 2px solid #ccc;
    border-radius: var(--radius);
    &::after {
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        background-image: url(${CheckboxTick});
        background-size: 60%;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.checked {
        border-color: var(--clr-green-dark);
        background-color: var(--clr-green-dark);
        ::after {
            opacity: 1;
        }
    }
    &.checkbox {
        border-radius: 0.25em;
        &::after {
            border-radius: 0.25em;
        }
    }
    &.radio {
        border-radius: 50%;
        &::after {
            border-radius: 50%;
        }
    }
`;

export { StyledInput, StyledSpan };
