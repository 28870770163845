import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1rem;
        padding: 1.5rem 3rem;
        background-color: var(--clr-grey-1);
        border-radius: var(--radius);
    }
    &.Item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    &.IconWrapper {
        border-radius: 50%;
        background-color: #fff;
        min-height: 3.5rem;
        min-width: 3.5rem;
        margin-right: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        svg {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    @media (max-width: 800px) {
        &.Wrapper {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 1fr);
            grid-gap: 1.5rem;
            padding: 1.5rem 5%;
        }
        &.IconWrapper {
            min-height: 2.5rem;
            min-width: 2.5rem;
        }
    }
`;

const StyledH1 = styled.h1`
    font-size: 1.35rem;
    color: #fff;

    @media (max-width: 800px) {
        font-size: 1.1rem;
    }
`;

const StyledP = styled.p`
    color: #ccc;
    font-size: 0.9rem;
    @media (max-width: 800px) {
        font-size: 0.85rem;
    }
`;
export { StyledDiv, StyledH1, StyledP };
