import React, {useState, useEffect} from "react";
import Title from "../UI/Title/Title";
import {StyledDiv, StyledButton, StyledH1} from "./OrderSummaryStyled";
import "react-phone-input-2/lib/bootstrap.css";
import {MdPeopleOutline} from "react-icons/md";
import {BiBriefcaseAlt} from "react-icons/bi";
import SedanIcon from "../../assets/icons/sedan.inline.svg";
import BusIcon from "../../assets/icons/bus.inline.svg";
import {CardElement, useStripe, useElements} from "@stripe/react-stripe-js";
import MinivanIcon from "../../assets/icons/minivan.inline.svg";
import PaymentRequestButton from "../CheckOut/PaymentRequestButton";
import {v4} from "uuid";
import moment from "moment";
import axios from "axios";
import {REACT_APP_REST_RESOURCE_BASE_END_POINT} from "../../constants/apiEndpoints";

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const CARD_OPTIONS = {
    iconStyle: "solid",
    hidePostalCode: true,
    style: {
        base: {
            iconColor: "#ccc",
            color: "#000",
            backgroundColor: "#fff",
            fontWeight: 400,
            padding: "1rem",
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            border: "1px solid #ccc",
            ":-webkit-autofill": {
                color: "#000",
            },
            "::placeholder": {
                color: "#ccc",
            },
        },
        invalid: {
            iconColor: "#595959",
            color: "#595959",
        },
    },
};

const SubmitButton = ({processing, error, children, disabled, onClick}) => (
    <StyledButton
        onClick={onClick}
        className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
        type="submit"
        disabled={processing || disabled}
    >
        {processing ? "Processing..." : children}
    </StyledButton>
);

const OrderSummary = ({
                          date,
                          fromData,
                          toData,
                          price,
                          selectedPaymentMethod,
                          setSelectedPaymentMethod,
                          luggageCount,
                          passengersCount,
                          vehicleType,
                          editButtonClicked,
                          sendTransferDetails,
                          validateInputFields,
                          setModalState,
                          email,
                          mobileNumber,
                          name,
                      }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [cardComplete, setCardComplete] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [clientSecret, setClientSecret] = useState("");

    let Icon = SedanIcon;
    if (vehicleType.toLowerCase().includes("bus")) {
        Icon = BusIcon;
    } else if (vehicleType.toLowerCase().includes('minivan')) {
        Icon = MinivanIcon;
    }

    useEffect(() => {
        try {
            let data = JSON.stringify({
                uuid: v4(),
                price: price,
            });

            axios
                .post(`${REACT_APP_REST_RESOURCE_BASE_END_POINT}/create-payment-intent`, data, {
                    headers: {"Content-Type": "application/json"},
                })
                .then((res) => setClientSecret(res.data));
        } catch (err) {
            console.log(err);
        }
    }, []);

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();

        const validInputFields = validateInputFields();

        if (!validInputFields) return;

        //if user has selected cash to driver then
        if (selectedPaymentMethod !== "card") return sendTransferDetails(true);

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        if (error) {
            elements.getElement("card").focus();
        }

        if (cardComplete) {
            setProcessing(true);
        }

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                type: "card",
                card: elements.getElement(CardElement),
                billing_details: {email: email, phone: mobileNumber, name: name},
            },
        });

        if (payload.error) {
            //todo When payment is not success
            setError(payload.error);
            if (error) {
                setModalState({
                    show: true,
                    text: `Error processing your payment`,
                    onCancel: () => setModalState({}),
                    cancelText: "Cancel",
                });
            }
        } else {
            // Payment have been success
            await sendTransferDetails(false, payload.paymentIntent.id);
            reset();
        }
    };

    const reset = () => {
        setError(null);
        setProcessing(false);
    };

    return (
        <StyledDiv className="MainWrapper">
            <StyledDiv className="Wrapper">
                <StyledDiv className="Cell">
                    <Title style={{marginBottom: "1rem"}}>Order Summary</Title>
                </StyledDiv>
                <StyledDiv className="Cell row" style={{marginBottom: "0.5rem"}}>
                    <Title small>{date ? moment(date).format("D MMMM, ddd H:mm") : null}</Title>
                    <StyledButton onClick={editButtonClicked} className="Edit">
                        Edit
                    </StyledButton>
                </StyledDiv>
                <StyledDiv className="OrderInfo">
                    <StyledDiv className="Left">
                        <div className="circleTop"/>
                        <div className="Line"/>
                        <div className="circleBottom"/>
                    </StyledDiv>
                    <StyledDiv className="Right">
                        <StyledDiv className="Item">
                            <Title medium style={{marginBottom: 0}}>
                                {fromData.formattedSuggestion.mainText}
                            </Title>
                            <Title small>{fromData.formattedSuggestion.secondaryText}</Title>
                        </StyledDiv>
                        <StyledDiv className="Item">
                            <Title medium style={{marginBottom: 0}}>
                                {toData.formattedSuggestion.mainText}
                            </Title>
                            <Title small>{toData.formattedSuggestion.secondaryText}</Title>
                        </StyledDiv>
                    </StyledDiv>
                </StyledDiv>
                <StyledDiv className="IconsWrapper">
                    <StyledDiv className="IconGroup">
                        {/*<Icon />*/}
                        <span>{capitalizeFirstLetter(vehicleType)}</span>
                    </StyledDiv>
                    <StyledDiv className="IconGroup">
                        <MdPeopleOutline/>
                        <span>{passengersCount}</span>
                    </StyledDiv>
                    <StyledDiv className="IconGroup">
                        <BiBriefcaseAlt/>
                        <span>{luggageCount}</span>
                    </StyledDiv>
                </StyledDiv>
                <StyledDiv className="bottomBorder Cell"/>
                <StyledDiv className="Cell PaymentWrapper">
                    <Title>Payment</Title>
                    <StyledDiv className="PaymentInfo">
                        <StyledDiv className="PriceWrapper">
                            {/* <StyledSpan>€55</StyledSpan> */}
                            <StyledH1 className="PriceFinal">€{price}</StyledH1>
                        </StyledDiv>
                        <Title small>Total price</Title>
                        <Title small>Taxes {"&"} fess included</Title>
                    </StyledDiv>
                </StyledDiv>
                <StyledDiv className="PaymentMethodsButtons ">
                    <StyledButton
                        onClick={() => setSelectedPaymentMethod("card")}
                        className={`PaymenntButton ${selectedPaymentMethod === "card" ? "active" : ""}`}
                    >
                        CARD
                    </StyledButton>
                    <StyledButton
                        onClick={() => setSelectedPaymentMethod("cash")}
                        className={`PaymenntButton ${selectedPaymentMethod === "cash" ? "active" : ""}`}
                    >
                        Cash To Driver
                    </StyledButton>
                </StyledDiv>
            </StyledDiv>
            <form onSubmit={handleSubmit}>
                {selectedPaymentMethod === "card" ? (
                    <>
                        <StyledDiv className="PaymentRequestButtonHeader">
                            <PaymentRequestButton uuid={v4()}/>
                        </StyledDiv>

                        <StyledDiv className="PayOptionHeader">
                            <span/> Pay With Card <span/>
                        </StyledDiv>
                        <StyledDiv className="CardWrapper">
                            <CardElement
                                options={CARD_OPTIONS}
                                onChange={(e) => {
                                    setError(e.error);
                                    setCardComplete(e.complete);
                                }}
                            />
                        </StyledDiv>
                    </>
                ) : null}
                <SubmitButton
                    processing={processing}>{selectedPaymentMethod === "card" ? `Pay ${price}` : "Continue"}</SubmitButton>
            </form>
        </StyledDiv>
    );
};

export default OrderSummary;
