import React from "react";
import { StyledDiv, StyledH1, StyledSpan } from "./ProgressStepsStyled";
import { AiOutlineCheck } from "react-icons/ai";

const ProgressSteps = ({ active }) => {
    return (
        <StyledDiv className="Wrapper">
            <StyledDiv className="InnerWrapper">
                <StyledDiv className="Item">
                    <StyledDiv className={`IconWrapper ${active >= 1 ? "active" : ""}`}>
                        {active <= 1 ? <StyledH1>1</StyledH1> : <AiOutlineCheck />}
                    </StyledDiv>
                    <StyledH1>Quote</StyledH1>
                </StyledDiv>
                <StyledSpan></StyledSpan>
                <StyledDiv className="Item">
                    <StyledDiv className={`IconWrapper ${active >= 2 ? "active" : ""}`}>
                        {active <= 2 ? <StyledH1>2</StyledH1> : <AiOutlineCheck />}
                    </StyledDiv>
                    <StyledH1>Transfer</StyledH1>
                </StyledDiv>
                <StyledSpan></StyledSpan>
                <StyledDiv className="Item">
                    <StyledDiv className={`IconWrapper ${active >= 3 ? "active" : ""}`}>
                        {active <= 3 ? <StyledH1>3</StyledH1> : <AiOutlineCheck />}
                    </StyledDiv>
                    <StyledH1>Payment</StyledH1>
                </StyledDiv>
            </StyledDiv>
        </StyledDiv>
    );
};

export default ProgressSteps;
