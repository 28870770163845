import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        padding: 1.25rem 0;
        background-color: #fff;
    }
    &.InnerWrapper {
        margin: auto;
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        max-width: 1000px;
    }
    &.Item {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
    &.IconWrapper {
        border-radius: 50%;
        min-height: 2rem;
        min-width: 2rem;
        margin-right: 0.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        border: 2px solid var(--clr-primary-3);

        svg {
            width: 1.1rem;
            height: 1.1rem;
            color: #fff;
        }

        h1 {
            color: var(--clr-primary-3);
        }

        &.active {
            background-color: var(--clr-primary-3);

            h1 {
                color: #fff;
            }
        }
    }

    @media (max-width: 800px) {
        &.IconWrapper {
            min-height: 1.5rem;
            min-width: 1.5rem;
        }
    }
`;

const StyledH1 = styled.h1`
    font-size: 1rem;
    white-space: nowrap;

    @media (max-width: 800px) {
        font-size: 0.8rem;
    }
`;

const StyledSpan = styled.span`
    height: 2px;
    background-color: var(--clr-primary-3);
    width: auto;
    flex: 1;
    margin: 0 1rem;
    min-width: 3rem;
    @media (max-width: 800px) {
        margin: 0 0.5rem;
        min-width: 1rem;
    }
`;

export { StyledDiv, StyledH1, StyledSpan };
