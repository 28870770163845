import React, { memo } from "react";
import { StyledInput, StyledSpan } from "./CheckboxStyled";
import PropTypes from "prop-types";

const Checkbox = ({ type, checked, onClick }) => {
    return (
        <div onClick={onClick}>
            <StyledInput classname="CheckboxInput"></StyledInput>
            <StyledSpan className={`CheckboxInputSpan ${type} ${checked ? "checked" : ""}`} />
        </div>
    );
};

Checkbox.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

export default memo(Checkbox);
