import styled from "styled-components";

const StyledDiv = styled.div`
    &.MainWrapper {
        background-color: #fff;
        border-radius: var(--radius);
        padding: 2rem 0 1.5rem;
        position: sticky;
        top: 1rem;
    }
    &.Wrapper {
        padding: 0 2.25rem 0.25rem;
    }
    &.Cell {
        margin-bottom: 1rem;
    }
    &.row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &.OrderInfo {
        display: grid;
        grid-template-columns: 10px auto;
        grid-column-gap: 0.45rem;
        margin-left: calc(-0.45rem - 10px);

        .Left {
            position: relative;
            .Line {
                width: 2px;
                background-color: var(--clr-green-dark);
                height: 75%;
                position: absolute;
                top: 12.5%;
                left: 50%;
                transform: translateX(-50%);
            }
            .circleTop,
            .circleBottom {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 2px solid var(--clr-green-dark);
                left: 50%;
                transform: translateX(-50%);
                background-color: #fff;
                z-index: 2;
            }
            .circleTop {
                top: calc(12.5% - 5px);
            }
            .circleBottom {
                bottom: calc(12.5% - 5px);
            }
        }
        .Right {
            width: 75%;
            .Item {
                margin-bottom: 1rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.bottomBorder {
        width: 100%;
        height: 1px;
        background-color: #e9ecef;
    }
    &.IconsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 0.75rem 0;
    }
    &.IconGroup {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-right: 0.75rem;
        svg {
            height: 1.65rem;
            width: auto;
            margin-right: 0.5rem;
            color: #595959;
        }
    }
    &.PaymentInfo {
        text-align: right;
    }
    &.PaymentWrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
    &.PriceWrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
    }
    &.PaymentMethodsButtons {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 0.5rem;
        padding-bottom: 1rem;
    }
    &.PayOptionHeader {
        background-color: var(--clr-grey-10);
        color: #595959;
        padding: 0.25rem;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            height: 1px;
            width: auto;
            flex: 1;
            background-color: #595959;
            margin: 0.75rem;
        }
    }

    &.CardWrapper {
        padding: 1rem 2rem;
    }
`;

const StyledButton = styled.button`
    &.Edit {
        background-color: transparent;
        border: none;
        outline: none;
        padding: 3px 5px;
        color: #595959;
        cursor: pointer;
        font-size: 0.95rem;

        &:hover {
            text-decoration: underline;
        }
    }
    &.PaymenntButton {
        background-color: #fff;
        border: solid 1px #ccc;
        width: 100%;
        height: 100%;
        padding: 0.75rem 0.5rem;
        border-radius: var(--radius);
        cursor: pointer;
        font-weight: 700;
        outline: none;
        box-sizing: border-box;

        &.active {
            border: 2px solid var(--clr-green-dark);
        }
    }
    &.SubmitButton {
        display: block;
        font-size: 1rem;
        padding: 1rem 0;
        width: calc(100% - 4rem);
        margin: auto;
        outline: none;
        border: none;
        margin-top: 0.5rem;
        background-color: var(--clr-green-dark);
        border-radius: var(--radius);
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        will-change: transform, background-color, box-shadow;

        &:active {
            box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #eee;
            transform: scale(0.99);
        }
        &:hover {
            opacity: 0.8;
        }
        &.SubmitButton--error {
            transform: translateY(15px);

            &:active {
                transform: scale(0.99) translateY(15px);
            }
            &:disabled {
                opacity: 0.5;
                cursor: default;
                background-color: #ccc;
                box-shadow: none;
            }
        }
    }
`;

const StyledSpan = styled.span`
    color: var(--clr-green-dark);
    font-size: 1.25rem;
    font-weight: 700;
    text-decoration: line-through;
    margin-right: 0.5rem;
`;

const StyledH1 = styled.h1`
    font-size: 1.75rem;
    margin-bottom: 0.25rem;
    color: var(--clr-green-dark);
`;

export { StyledDiv, StyledButton, StyledSpan, StyledH1 };
