import React, { useReducer, useEffect, useState } from "react";
import TopBanner from "../components/TopBanner/TopBanner";
import styled from "styled-components";
import ProgressSteps from "../components/ProgressSteps/ProgressSteps";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import BookingDetails from "../components/BookingDetails/BookingDetails";
import OrderSummary from "../components/OrderSummary/OrderSummary";
import { navigate } from "gatsby";
import moment from "moment";
import BookingFormModal from "../components/BookingFormModal/BookingFormModal";
import { defaultOptions } from "../constants/toursDefaultValues";
import axios from "axios";
import { REACT_APP_REST_RESOURCE_BASE_END_POINT } from "../constants/apiEndpoints";
import { checkValidity } from "../utils/checkValidity";
import Modal from "../components/Modal/Modal";
import VehicleCard from "../components/CarCard/VehicleCard";

const stripePromise = loadStripe("pk_test_okk0TZerRWR9ZCyiLFGJ1OYK002vAhrkeS");

const defaultState = {
    flightNumber: "",
    fullName: "",
    mobileNumber: "",
    email: "",
    statusUpdates: false,
    childSeats: "",
    notesToDriverCheck: false,
    notesToDriver: "",
    selectedPaymentMethod: "card",
    errors: {
        flightNumber: "",
        fullName: "",
        mobileNumber: "",
        email: "",
    },
    checkoutData: null,
    showEditModal: false,
    error: null,
    processing: false,
    bulkyLuggageCheckbox: false,
    wheelChairCheckbox: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "FLIGHT_NUMBER_CHANGE":
            return { ...state, flightNumber: action.flightNumber };
        case "FULL_NAME_CHANGE":
            return { ...state, fullName: action.fullName };
        case "EMAIL_CHANGE":
            return { ...state, email: action.email };
        case "MOBILE_NUMBER_CHANGE":
            return { ...state, mobileNumber: action.mobileNumber };
        case "STATUS_UPDATE_CHANGE":
            return { ...state, statusUpdates: !state.statusUpdates };
        case "CHILD_SEATS_CHANGE":
            return { ...state, childSeats: !state.childSeats };
        case "NOTES_TO_DRIVER_CHECK_CHANGE":
            return { ...state, notesToDriverCheck: !state.notesToDriverCheck };
        case "NOTES_TO_DRIVER_CHANGE":
            return { ...state, notesToDriver: action.notesToDriver };
        case "WHEELCHAIR_CHANGE":
            return { ...state, wheelChairCheckbox: !state.wheelChairCheckbox };
        case "BULKY_LUGGAGE_CHANGE":
            return { ...state, bulkyLuggageCheckbox: !state.bulkyLuggageCheckbox };
        case "PAYMENT_METHOD_CHANGE":
            return { ...state, selectedPaymentMethod: action.selectedPaymentMethod };
        case "SET_CHECKOUT_DATA":
            return { ...state, checkoutData: action.data };
        case "SHOW_EDIT_MODAL":
            return { ...state, showEditModal: true };
        case "HIDE_EDIT_MODAL":
            return { ...state, showEditModal: false };
        case "SET_ERRORS":
            return { ...state, errors: action.errors };
        default:
            throw new Error();
    }
};

const VehicleList = ({ location }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const [modalState, setModalState] = useState({
        show: false,
        text: "",
        onConfirm: null,
        onCancel: null,
        confirmText: "",
        cancelText: "",
    });

    useEffect(() => {
        const data = JSON.parse(localStorage.getItem("checkoutData"));
        if (
            !data ||
            !data.vehicleType ||
            !data.fromData ||
            !data.toData ||
            !data.price ||
            !data.date ||
            !data.luggageCount ||
            !data.passengersCount ||
            (data.date && moment().isAfter(data.date))
        ) {
            localStorage.removeItem("checkoutData");
            navigate("/");
        } else {
            dispatch({ type: "SET_CHECKOUT_DATA", data: data });
        }
    }, []);

    const sendTransferDetails = async (cashToDriver, paymentIntentId) => {
        const payload = {
            transferDateTime: state.checkoutData.date,
            origin: state.checkoutData.fromData.e.description,
            destination: state.checkoutData.toData.e.description,
            passengerName: state.fullName,
            transferPrice: state.checkoutData.price,
            originLng: state.checkoutData.fromData.latLng.lng,
            originLat: state.checkoutData.fromData.latLng.lat,
            destinationLng: state.checkoutData.toData.latLng.lng,
            destinationLat: state.checkoutData.toData.latLng.lat,
            vehicleEnum: state.checkoutData.vehicleType, // The Enum It Self
            vehicleDisplayName: state.checkoutData.vehicleName, // DisplayName Of The Enum
            paymentMethodId: cashToDriver ? 1 : 2, // if CASH TO Driver is selected by user = id = 2 if Paid online Id = 3
            driverDetails: state.notesToDriver, // long text
            passengerEmail: state.email,
            paymentIntentId: paymentIntentId,
            passengerPhoneNumber: state.mobileNumber,
            numberOfBags: state.checkoutData.luggageCount,
            numberOfPassengers: state.checkoutData.passengersCount,

            // bulkyLuggage: state.bulkyLuggageCheckbox, // boolean
            // wheelChairAccess: state.wheelChairCheckbox, // boolean
            // childSeatsNumber: "", // this will be an int
            // clientId: "", // this will be internal logic
            // ownerCompanyId: "", // this will be internal logic

        };

        try {
            await axios({
                method: "POST",
                url: `${REACT_APP_REST_RESOURCE_BASE_END_POINT}/checkout`,
                data: JSON.stringify(payload),
                headers: { "Content-Type": "application/json" },
            });
            navigate("/thankyou");
        } catch (err) {

            if (cashToDriver) {
                setModalState({
                    show: true,
                    text: "There was a problem with the connection to the server, please contact with us to confirm your booking",
                    //onConfirm: () => console.log("On COnfirm"), //sendTransferDetails(cashToDriver),
                    onCancel: () =>  navigate("/"),//setModalState({}), // home
                    //confirmText: "Try again",
                    cancelText: "Okay, I will contact Grondas right away",
                });
            } else {
                setModalState({
                    show: true,
                    text: "Your Payment Has Been Received, but there was an problem with the connection to the server please contact with us to confirm your booking",
                    //onConfirm: () => console.log("On COnfirm"), //sendTransferDetails(cashToDriver),
                    onCancel: () =>  navigate("/"),//setModalState({}), // home
                    //confirmText: "Try again",
                    cancelText: "Okay, I will contact Grondas right away",
                });
            }
        }
    };

    const onBookingModalSubmit = (state, type, vehiclePrice, maxLuggage, maxPassengers, vehicleId) => {
        const data = {
            date: state.date,
            fromData: state.fromData,
            toData: state.toData,
            price: vehiclePrice,
            luggageCount: state.luggageCount,
            passengersCount: state.passengersCount,
            maxLuggage: maxLuggage,
            maxPassengers: maxPassengers,
            vehicleType: type,
            vehicleId: vehicleId,
        };
        dispatch({ type: "SET_CHECKOUT_DATA", data: data });
        localStorage.setItem("checkoutData", JSON.stringify(data));
    };

    const validateInputFields = () => {
        let valid = true;

        let errors = {};

        if (!checkValidity(state.flightNumber || "", { required: true })) {
            errors = { ...errors, flightNumber: "Required" };
            valid = false;
        } else {
            errors = { ...errors, flightNumber: "" };
        }

        if (!checkValidity(state.fullName || "", { required: true })) {
            errors = { ...errors, fullName: "Required" };
            valid = false;
        } else {
            errors = { ...errors, fullName: "" };
        }

        if (!checkValidity(state.email || "", { required: true, email: true })) {
            errors = { ...errors, email: "Required" };
            valid = false;
        } else {
            errors = { ...errors, email: "" };
        }

        if (!checkValidity(state.mobileNumber || "", { required: true, number: true })) {
            errors = { ...errors, mobileNumber: "Required" };
            valid = false;
        } else {
            errors = { ...errors, mobileNumber: "" };
        }

        dispatch({ type: "SET_ERRORS", errors: { ...state.errors, ...errors } });

        return valid;
    };

    if (!state.checkoutData) {
        return null;
    }

    return (
        <StyledDiv className="Wrapper">
            <ProgressSteps active={3} />
            <StyledDiv className="TopBannerWrapper">
                {/*<TopBanner />*/}
            </StyledDiv>
            <StyledDiv className="Main">
                <div>
                    <BookingDetails
                        errors={state.errors}
                        flightNumberChange={(e) => dispatch({ type: "FLIGHT_NUMBER_CHANGE", flightNumber: e.target.value })}
                        flightNumberValue={state.flightNumber}
                        fullNameChange={(e) => dispatch({ type: "FULL_NAME_CHANGE", fullName: e.target.value })}
                        fullNameValue={state.fullName}
                        mobileNumberChange={(e) => dispatch({ type: "MOBILE_NUMBER_CHANGE", mobileNumber: e })}
                        mobileNumberValue={state.mobileNumber}
                        emailChange={(e) => dispatch({ type: "EMAIL_CHANGE", email: e.target.value })}
                        emailValue={state.email}
                        statusUpdateChange={() => dispatch({ type: "STATUS_UPDATE_CHANGE" })}
                        statusUpdateValue={state.statusUpdates}
                        childSeatsChange={() => dispatch({ type: "CHILD_SEATS_CHANGE" })}
                        childSeatsValue={state.childSeats}
                        notesToDriverCheckChange={() => dispatch({ type: "NOTES_TO_DRIVER_CHECK_CHANGE" })}
                        notesToDriverCheckValue={state.notesToDriverCheck}
                        notesToDriverChange={(e) => dispatch({ type: "NOTES_TO_DRIVER_CHANGE", notesToDriver: e.target.value })}
                        notesToDriverValue={state.notesToDriver}
                        wheelChairChange={() => dispatch({ type: "WHEELCHAIR_CHANGE" })}
                        wheelChairValue={state.wheelChairCheckbox}
                        bulkyLuggageChange={() => dispatch({ type: "BULKY_LUGGAGE_CHANGE" })}
                        bulkyLuggageValue={state.bulkyLuggageCheckbox}
                    />
                </div>
                <div>
                    <Elements stripe={stripePromise}>
                        <OrderSummary
                            email={state.email}
                            mobileNumber={state.mobileNumber}
                            name={state.fullName}
                            setModalState={setModalState}
                            billing_details={{
                                email: state.email,
                                phone: state.mobileNumber,
                                name: state.fullName,
                            }}
                            validateInputFields={validateInputFields}
                            vehicleType={state.checkoutData.vehicleName}
                            fromData={state.checkoutData.fromData.e}
                            toData={state.checkoutData.toData.e}
                            price={state.checkoutData.price}
                            date={state.checkoutData.date}
                            selectedPaymentMethod={state.selectedPaymentMethod}
                            setSelectedPaymentMethod={(e) => dispatch({ type: "PAYMENT_METHOD_CHANGE", selectedPaymentMethod: e })}
                            editButtonClicked={() => dispatch({ type: "SHOW_EDIT_MODAL" })}
                            sendTransferDetails={sendTransferDetails}
                            luggageCount={state.checkoutData.luggageCount}
                            passengersCount={state.checkoutData.passengersCount}
                        />
                    </Elements>
                </div>
            </StyledDiv>
            <BookingFormModal
                checkoutData={state.checkoutData}
                defaultOptions={defaultOptions}
                defaultState={state.checkoutData}
                onSubmit={onBookingModalSubmit}
                onClose={() => dispatch({ type: "HIDE_EDIT_MODAL" })}
                show={state.showEditModal}
            />
            <Modal
                confirmText={modalState.confirmText}
                cancelText={modalState.cancelText}
                show={modalState.show}
                onCancel={modalState.onCancel}
                text={modalState.text}
            />
        </StyledDiv>
    );
};

const StyledDiv = styled.div`
    &.Wrapper {
        background-color: #e9ecef;
        min-height: 100vh;
    }
    &.TopBannerWrapper {
      
        margin: 1rem auto;
        width: 50%;
        min-width: 800px;
        max-width: 1200px;
    }
    &.Main {
        display: grid;
        grid-template-columns: 55% auto;
        grid-gap: 1rem;
        margin: auto;
        width: 50%;
        min-width: 800px;
        max-width: 1200px;
        padding-bottom: 1rem;
    }
    @media (max-width: 800px) {
        &.TopBannerWrapper {
            width: 100%;
            min-width: auto;
        }
        &.Main {
            grid-template-columns: 1fr;
            min-width: auto;
            width: 100%;
        }
    }
`;

export default VehicleList;
