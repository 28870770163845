import React from "react";
import Input from "../UI/Input/Input";
import Title from "../UI/Title/Title";
import { StyledDiv } from "./BookingDetailsStyled";
import { AiOutlineSearch } from "react-icons/ai";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Checkbox from "../UI/Checkbox/Checkbox";
import TextArea from "../UI/TextArea/TextArea";

const BookingDetails = ({
    flightNumberChange,
    flightNumberValue,
    fullNameChange,
    fullNameValue,
    mobileNumberChange,
    mobileNumberValue,
    emailChange,
    emailValue,
    statusUpdateChange,
    statusUpdateValue,
    childSeatsChange,
    childSeatsValue,
    notesToDriverCheckChange,
    notesToDriverCheckValue,
    notesToDriverChange,
    notesToDriverValue,
    wheelChairChange,
    wheelChairValue,
    bulkyLuggageChange,
    bulkyLuggageValue,
    errors,
}) => {
    return (
        <StyledDiv className="Wrapper">
            <StyledDiv className="Cell">
                <Title style={{ marginBottom: "1rem" }}>Booking Details</Title>
            </StyledDiv>
            <StyledDiv className="Cell">
                <Title medium>Flight number</Title>
                <Input
                    error={errors.flightNumber}
                    value={flightNumberValue}
                    onChange={flightNumberChange}
                    rightIcon={AiOutlineSearch}
                    placeholder="Find my flight"
                />
            </StyledDiv>
            <StyledDiv className="Cell">
                <Title medium>Your full name</Title>
                <Input error={errors.fullName} onChange={fullNameChange} value={fullNameValue} placeholder="First and last name" />
            </StyledDiv>
            <StyledDiv className="PhoneInputWrapper Cell">
                <Title medium>Mobile phone number</Title>
                <PhoneInput
                    countryCodeEditable
                    disableCountryCode={false}
                    buttonClass="react-phone-dropdown-button"
                    inputClass={`react-phone-input ${errors.mobileNumber ? "error" : ""}`}
                    containerClass="react-phone-input-container"
                    dropdownClass="react-phone-input-dropdown-container"
                    placeholder="Mobile number"
                    inputProps={{
                        name: "phone",
                        required: true,
                    }}
                    country={"gr"}
                    onChange={mobileNumberChange}
                    value={mobileNumberValue}
                />
            </StyledDiv>
            <StyledDiv className="Cell">
                <Title medium>Email</Title>
                <Input error={errors.email} onChange={emailChange} value={emailValue} placeholder="Email" type="email" />
            </StyledDiv>
            <StyledDiv className="Cell">
                <StyledDiv className="row">
                    {/*<Checkbox type="checkbox" onClick={statusUpdateChange} checked={statusUpdateValue} />*/}
                    {/*<Title small style={{ marginLeft: "0.5rem", fontSize: "0.85rem" }}>*/}
                    {/*    I agree to receive status updates via email and sms*/}
                    {/*</Title>*/}
                </StyledDiv>
            </StyledDiv>
            <StyledDiv className="bottomBorder Cell"/>
            <StyledDiv className="Extras Cell">
                {/*<StyledDiv className="row">*/}
                {/*    <Checkbox type="checkbox" checked={childSeatsValue} onClick={childSeatsChange} />*/}
                {/*    <Title small style={{ marginLeft: "0.5rem", fontSize: "0.85rem" }}>*/}
                {/*        Add child seats*/}
                {/*    </Title>*/}
                {/*</StyledDiv>*/}
                <StyledDiv className="row">
                    <Checkbox type="checkbox" checked={notesToDriverCheckValue} onClick={notesToDriverCheckChange} />
                    <div>
                        <Title medium style={{ marginLeft: "0.5rem", marginBottom: 0, fontSize: "0.9rem" }}>
                            notes for the driver
                        </Title>
                        {/*<Title small style={{ marginLeft: "0.5rem", fontSize: "0.85rem" }}>*/}
                        {/*    Bulky luggage, wheelchair, etc.*/}
                        {/*</Title>*/}
                    </div>
                </StyledDiv>
            </StyledDiv>

            {notesToDriverCheckValue ? (
                <>
                    <StyledDiv className="Cell">
                        <TextArea onChange={notesToDriverChange} value={notesToDriverValue}
                                  placeholder="Please Enter Any Request You May Have (child seats, etc)" />
                    </StyledDiv>
                    {/*<StyledDiv className="Extras Cell">*/}
                    {/*    <StyledDiv className="row">*/}
                    {/*        <Checkbox type="checkbox" checked={bulkyLuggageValue} onClick={bulkyLuggageChange} />*/}
                    {/*        <div>*/}
                    {/*            <Title medium style={{ marginLeft: "0.5rem", marginBottom: 0, fontSize: "0.85rem" }}>*/}
                    {/*                Bulky luggage*/}
                    {/*            </Title>*/}
                    {/*            <Title small style={{ marginLeft: "0.5rem", fontSize: "0.8rem" }}>*/}
                    {/*                Bikes, snowboards, big boxes, etc.*/}
                    {/*            </Title>*/}
                    {/*        </div>*/}
                    {/*    </StyledDiv>*/}
                    {/*    <StyledDiv className="row">*/}
                    {/*        <Checkbox type="checkbox" checked={wheelChairValue} onClick={wheelChairChange} />*/}
                    {/*        <div>*/}
                    {/*            <Title medium style={{ marginLeft: "0.5rem", marginBottom: 0, fontSize: "0.85rem" }}>*/}
                    {/*                Wheelchair Accessible vehicle*/}
                    {/*            </Title>*/}
                    {/*        </div>*/}
                    {/*    </StyledDiv>*/}
                    {/*</StyledDiv>*/}
                </>
            ) : null}

            <StyledDiv className="row">
                {/*<Checkbox type="checkbox" onClick={statusUpdateChange} checked={statusUpdateValue} />*/}
                <Title small //style={{ marginLeft: "0.5rem", fontSize: "0.85rem" }}
                >
                    By Booking With Us You Agree To Our Terms And Conditions
                </Title>
            </StyledDiv>

        </StyledDiv>
    );
};

export default BookingDetails;
