import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        position: sticky;
        top: 1rem;
        display: flex;
        flex-direction: column;
        padding: 2rem 5% 2rem;
        background-color: #fff;
        border-radius: var(--radius);

        & > * {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    &.Cell {
        margin-bottom: 1rem;
    }
    &.PhoneInputWrapper {
        .react-phone-input {
            width: 100%;
        }

        .react-tel-input .form-control {
            height: auto;
            padding-top: 0.75em;
            padding-bottom: 0.75em;
            border-radius: var(--radius);
            line-height: 1;
            width: 100%;
            font-size: 1rem;
            font-family: "Open Sans", sans-serif;
            border: 1px solid #ccc;
            transition: none;
            margin: 0;

            &:focus {
                outline: none;
                box-shadow: 0 0 0 1px #000;
            }

            &.error {
                color: var(--clr-red-dark);
                border: 1px solid var(--clr-red-dark);
            }

            &::placeholder {
                font-size: 0.9rem;
                line-height: 1rem;
                color: gray;
                text-transform: capitalize;
                letter-spacing: 1px;
            }
        }
    }

    &.row {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.center {
            justify-content: center;
            align-items: center;
        }
    }
    &.InputCell {
        display: flex;
    }
    &.bottomBorder {
        width: 100%;
        height: 1px;
        background-color: #e9ecef;
    }
    &.Extras {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;

        @media (max-width: 500px) {
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;
        }
    }
`;

export { StyledDiv };
