import styled from "styled-components";

const StyledDiv = styled.div`
    &.Wrapper {
        background-color: #fff;
        border-radius: 0.5rem;
        padding: 2.5em 5%;
        width: 40rem;
        max-height: 95vh;
        max-width: 90vw;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
        box-sizing: border-box;
        &.top {
            z-index: 999;
        }
        & > * {
            margin-bottom: 1em;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.ButtonsWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & > button {
            margin: 0 0.5em;
            padding-left: 2em;
            padding-right: 2em;
        }
    }
`;

const StyledH1 = styled.h1`
    color: ${(props) => props.theme.dark_blue};
    font-size: 1.75rem;
    font-weight: bold;
    text-align: center;
`;

const StyledButton = styled.button`
    position: relative;
    font-size: 1rem;
    padding: 0.75em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.5em;
    line-height: 1.5rem;
    border-radius: var(--radius);
    color: #fff;
    border: none;
    cursor: pointer;
    outline: none;

    &:hover {
        opacity: 0.8;
    }

    &.Confirm {
        background-color: var(--clr-green-dark);
    }
    &.Cancel {
        background-color: var(--clr-red-light);
    }
`;

export { StyledDiv, StyledH1, StyledButton };
