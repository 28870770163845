import styled from "styled-components";

const StyledTextArea = styled.textarea`
    width: 100%;
    border: 1px solid #ccc;
    border-radius: var(--radius);
    font-size: 0.9rem;
    font-family: inherit;
    min-height: 10rem;
    max-height: 10rem;
    padding: 0.75em 1em;
    resize: none;
    background-color: #fff;
    color: #000;
    box-sizing: border-box;
    &:focus {
        outline: none;
        box-shadow: 0 0 0 1px #000;
    }
`;

export { StyledTextArea };
