import React, { memo } from "react";
import { StyledTextArea } from "./TextAreaStyled";
import PropTypes from "prop-types";

const TextArea = ({ className, onChange, value, placeholder, ...rest }) => {
    return (
        <StyledTextArea {...rest} placeholder={placeholder} className={`${className} StyledTextArea`} onChange={onChange} value={value} />
    );
};

TextArea.propTypes = {
    icon: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    show: PropTypes.bool,
    autoFocus: PropTypes.bool,
    focusable: PropTypes.bool,
};

export default memo(TextArea);
