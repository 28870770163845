import React from "react";
import { StyledDiv, StyledH1, StyledButton } from "./ModalStyled";
import Backdrop from "../UI/Backdrop/Backdrop";

const Modal = ({ text, onCancel, onConfirm, cancelText, confirmText, show, blockBackdropCancel, top }) => {
    return (
        <>
            {show ? (
                <StyledDiv className={`Wrapper ModalWrapper ${top ? "top" : ""}`}>
                    <>
                        <StyledH1 className="ModalH1">{text}</StyledH1>
                        <StyledDiv className="ButtonsWrapper ModalButtonsWrapper">
                            {confirmText ? (
                                <StyledButton
                                    className="Confirm"
                                    onClick={() => {
                                        if (onConfirm) onConfirm();
                                    }}
                                >
                                    {confirmText}
                                </StyledButton>
                            ) : null}
                            {cancelText ? (
                                <StyledButton
                                    className="Cancel"
                                    onClick={() => {
                                        if (onCancel) onCancel();
                                    }}
                                >
                                    {cancelText}
                                </StyledButton>
                            ) : null}
                        </StyledDiv>
                    </>
                </StyledDiv>
            ) : null}
            <Backdrop top show={show} onClick={!blockBackdropCancel ? onCancel : null} />
        </>
    );
};

export default Modal;
